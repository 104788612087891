import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { darken } from "polished";
import {
  IoOpenOutline,
  // IoLogoTwitter,
  // IoLogoLinkedin,
  IoChatbubbles,
  IoHelpBuoy,
} from "react-icons/io5";

import { useModal } from "../../components/useModal";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { Button, Container, Wrapper, Row, Box } from "../../components/util";
import { WebsiteContactForm } from "../../components/forms";
import { Modal, CallToAction } from "../../components/site";

const SupportCard = styled(Box)`
  border-radius: ${(props) => props.theme.styles.borderRadius};
  padding: 60px;
  text-align: center;
  box-shadow: ${(props) => props.theme.styles.shadow};

  &.-dark {
    background-color: ${(props) => darken(0.35, props.theme.colours.Primary)};
    color: white;
    a{
      color: white;
    }
  }

  &.-light {
    background-color: ${(props) => props.theme.colours.white};
    h2,
    p {
      color: ${(props) => props.theme.colours.dark} !important;
    }

    a.link-floating {
      color: ${(props) => props.theme.colours.orange} !important;
      border-bottom: solid 2px #E7F7E9;
    }
  }

  svg.-orange {
    font-size: 3.2rem;
    color: ${(props) => props.theme.colours.orange} !important;
    margin-bottom: 15px;

    * {
      color: ${(props) => props.theme.colours.orange} !important;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 30px;
  }
`;
const ContactHeroSection = styled(Box)`
padding: 50px 0px;
  background-color: #CFF0D3;
`;


const Contact = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();
  const [calendlySource, setCalendlySource] = useState("");

  const handleModal = function (e) {
    setItemModalOpen(true);
    setCalendlySource(e.target.name);
  };

  useEffect(
    function () {
      if (props.location.search.substr(1) === "sales") {
        setItemModalOpen(true);
      }
    },
    [props.location.search, setItemModalOpen]
  );

  return (
    <Layout>
      <Seo
        title="How Can We Help? | Karmly"
        description="We're a proudly New Zealand owned business based in Wellington. Have any questions? Here's how to get in touch with our friendly support team."
        pathname={props.location.pathname}
      />
      <ContactHeroSection className="contact-hero">
        <Wrapper className="-textCenter" stackGap={80}>
          <h1>Hello. How can we help?</h1>
          <Row stackGap={40}>
            <SupportCard className="-dark" stackGap={40} size={50}>
              <Box>
                <IoChatbubbles className="-orange" />
                <h2>I'm new here</h2>
              </Box>
              <Box stackGap={30}>
                <Button
                  name="karmly/15min"
                  className="primary -lg -center gtm-cta"
                  to="/recruitment/signup"
                >
                  Get Started
                </Button>
                <Link
                  className="-center link-floating"
                  to="/recruitment/contact#message"
                  css={{ fontSize: "1rem!important" }}
                >
                  Send us a message
                </Link>
              </Box>
            </SupportCard>
            <SupportCard className="-light" stackGap={40} size={50}>
              <Box>
                <IoHelpBuoy className="-orange" />
                <h2>I’m an existing customer</h2>
              </Box>
              <Box stackGap={30}>
                <Button
                  className="primary -lg -center"
                  to="https://support.karmly.com/hc/en-us"
                  target="_blank"
                  rel="noreferrer"
                  atag
                  externalLink
                >
                  Visit Support Centre{" "}
                  <IoOpenOutline css={{ top: "2px", marginLeft: "3px" }} />
                </Button>
                <a
                  href="#!"
                  name="karmly/support"
                  className="-center link-floating"
                  onClick={handleModal}
                  css={{ fontSize: "1rem!important" }}
                >
                  Schedule Support Call
                </a>
              </Box>
            </SupportCard>
          </Row>
        </Wrapper>
      </ContactHeroSection>
      <div className="site-anchor" id="message" />
      <Container>
        <Wrapper stackGap={80}>
          <Row stackGap={60}>
            <Box size={50} stackGap={50}>
              <div>
                <h2>Send us a message</h2>
                <p>
                  How can we help you today?
                </p>
              </div>
              <div>
                <WebsiteContactForm />
              </div>
            </Box>
            <Box size={50} stackGap={45}>
              <Box stackGap={25}>
                <div>
                  <h3>Product Support</h3>
                  <p>
                    Having trouble getting set up or have a curly question you
                    can’t find the answer to? Email the team at{" "}
                    <a href="mailto:support@karmly.com">support@karmly.com</a>{" "}
                    or check our Support Centre for in depth articles about our
                    products.
                  </p>
                </div>
                <a
                  className="link-floating"
                  href="https://support.karmly.com/hc/en-us"
                  target="_black"
                >
                  Visit Support Centre <IoOpenOutline css={{ top: "2px" }} />
                </a>
              </Box>
              <hr />
              <Box>
                <Row stackGap={40}>
                  <Box size={60}>
                    <h3>Our Office</h3>
                    <p>We’re based in the best little capital in the world.</p>
                    <p>
                      <b>11 Chews Lane, Level 1</b>
                    </p>
                    <p>
                      <b>Willis Street</b>
                    </p>
                    <p>
                      <b>PO Box 10067</b>
                    </p>
                    <p>
                      <b>Wellington 6143</b>
                    </p>
                    <p>
                      <b>New Zealand</b>
                    </p>
                  </Box>
                  {/* <Box size={40} stackGap={20}>
                    <div>
                      <h3>Socials</h3>
                      <p>Stay up to date with Invoxy.</p>
                    </div>
                    <Row stackGap={15} justify="flex-start" noBreak>
                      <a
                        href="https://twitter.com/invoxy"
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{ fontSize: "2rem" }}
                        aria-label="Follow us on Twitter"
                      >
                        <IoLogoTwitter />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/invoxy"
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{ fontSize: "2rem" }}
                        aria-label="Follow us on LinkedIn"
                      >
                        <IoLogoLinkedin />
                      </a>
                    </Row>
                  </Box> */}
                </Row>
              </Box>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <CallToAction />
      <Modal
        isActive={itemModalOpen}
        embedURL={`https://calendly.com/${calendlySource}`}
        embedTitle="Book a Call | Karmly"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Contact;
